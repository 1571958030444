div#survey-table-container{
    border: black solid 1px;
    border-radius: 7px;
    padding: 5px;
    overflow: auto;
    align-content: left;
    text-align: left;
}

div#survey-table-container{
    margin: 20px 0px;
}

table, th, td {
    padding: 5px;
    margin: 10px 0px;
}

tr:nth-child(odd) {background: rgb(240, 237, 237)}
tr:nth-child(even) {background: #FFF}