.App {
  text-align: left;
}

h1,h2 {
  text-align: center;
}

.footer{
  background-color: black;
  color: whitesmoke;
  margin-top: 10%;
  text-align: center;
  padding:10px;
}