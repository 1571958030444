.dropdown-menu{
    max-height: 300px;
    overflow: auto;
}

#dropdown_input_filter{
    width: -webkit-fill-available;
    margin: 3px;
    color: black!important;
}

.label-cursor-pointer{
    cursor: pointer;
}